<template>
	<div id="body">
		<!-- 广告创建页面头开始 -->
		<div class="header">
			<p class="headerTitle">
				<span class="title">创建广告</span><span>根据指示一步步来创建您的广告</span>
			</p>
		</div>
		<!-- 广告创建页面头结束 -->
		<!-- 广告创建页面步骤条开始 -->
		<div class="top">
			<el-steps class="topStep" :active="active" align-center process-status="finish" finish-status="success">
				<el-step title="填写广告计划"></el-step>
				<el-step title="设置投放范围"></el-step>
				<el-step title="编辑投放内容"></el-step>
				<el-step title="完成"></el-step>
			</el-steps>
		</div>
		<!-- 广告创建页面步骤条结束 -->
		<!-- 广告创建页面填写广告计划开始 -->
		<div class="stepOne" v-if="active==0">
			<div class="content">
				<ul>
					<li>
						<div class="div">
							<span>广告计划名称：</span>
							<el-input class="input1" size="medium" v-model="insert.advName" placeholder="当前最多可输入40个汉字"
								maxlength="40">
							</el-input>
						</div>
					</li>
					<li>
						<div class="div">
							<span>每日预算：</span>
							<el-radio-group v-model="everydayBudgetIs">
								<el-radio class="radio" :label="null" @change="insert.everydayBudget = ''">不限</el-radio>
								<el-radio class="radio" label="">自定义：</el-radio>
							</el-radio-group>
							<el-input class="input2" v-model="insert.everydayBudget" placeholder="请输入每日预算"
								:disabled="everydayBudgetIs == null">
								<template slot="append">元</template>
							</el-input>
						</div>
					</li>
					<li>
						<div class="div">
							<span>投放期限：</span>
							<el-date-picker style="width: 150px;" v-model="insert.launchStartDay"
								value-format="yyyy-MM-dd" type="date" placeholder="选择开始日期" @change="startDayChange"
								:picker-options="pickerOptions"></el-date-picker> -
							<el-date-picker style="width: 150px;" v-model="insert.launchEndDay"
								value-format="yyyy-MM-dd" type="date" placeholder="无结束日期" @change="endDayChange"
								:picker-options="pickerOptions"></el-date-picker>
						</div>
					</li>
					<li>
						<div class="div">
							<span>投放范围：</span>
							<el-radio-group v-model="insert.range">
								<el-radio class="radio" label="1">精准投放</el-radio>
								<el-radio class="radio" label="2">范围投放</el-radio>
							</el-radio-group>
						</div>
					</li>
					<li>
						<div class="div">
							<span>投放时段：</span>
							<el-radio-group v-model="launchStartHourIs">
								<el-radio class="radio" :label="null"
									@change="insert.launchStartHour = '0',insert.launchEndHour = '24'">
									全部时段</el-radio>
								<el-radio class="radio" label="">自定义：</el-radio>
							</el-radio-group>
							<el-time-select style="width: 150px;" v-model="insert.launchStartHour" :picker-options="{
                    start: '00:00',step: '01:00',end: '23:00'}" placeholder="投放开始时段"
								:disabled="launchStartHourIs == null">
							</el-time-select>-
							<el-time-select style="width: 150px;" v-model="insert.launchEndHour"
								:picker-options="{start: '01:00',step: '01:00',end: '24:00'}" placeholder="投放结束时段"
								:disabled="launchStartHourIs == null">
							</el-time-select>
						</div>
					</li>
					<li>
						<el-button class="next" size="mini" type="primary" @click="next()">下一步</el-button>
						<span class="hint">添加定向信息之后，推广计划才能生效</span>
					</li>
				</ul>
			</div>
			<div class="footer">
				<p>说明</p>
				<div class="footerBox">
					<div class="footerLeft">
						<p class="title">每日预算</p>
						<p >您每天的支出上限，每日预算用完之后将会停止投放</p>
						<p class="title">投放时间</p>
						<p>审核通过后，推广将会按照您设定的时间来投放</p>
					</div>
					<div class="footerRight">
						<p class="title">精准投放与范围投放</p>
						<p>精准投放：精准投放至指定的一所或多所学校</p>
						<p>范围投放：不指定学校但可以指定某一地区内的指定年级与性别范围</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 广告创建页面填写广告计划结束 -->
		<!-- 广告创建页面设置投放范围开始 -->
		<div class="stepTwo" v-if="active==1">
			<div class="content">
				<ul>
					<li>
						<div class="div">
							<span>地区：</span>
							<el-radio-group v-model="provinceIs">
								<el-radio class="radio" :label="null" @change="provinceChange">不限
								</el-radio>
								<el-radio class="radio" label="">指定区域：</el-radio>
							</el-radio-group>
							<el-select class="select" v-model="insert.province" placeholder="请选择省" @change="province"
								:disabled="provinceIs == null" filterable>
								<el-option v-for="item in provinceData" :key="item.ID" :label="item.Name"
									:value="item.ID">
								</el-option>
							</el-select>
							<el-select class="select" v-model="insert.city" placeholder="请选择市" @change="city"
								:disabled="provinceIs == null" filterable>
								<el-option v-for="item in cityData" :key="item.ID" :label="item.Name" :value="item.ID">
								</el-option>
							</el-select>
							<el-select class="select" v-model="insert.area" placeholder="请选择区" @change="area"
								:disabled="provinceIs == null" filterable>
								<el-option v-for="item in areaData" :key="item.ID" :label="item.Name" :value="item.ID">
								</el-option>
							</el-select>
						</div>
					</li>
					<li v-if="insert.range == '1'">
						<div class="div">
							<span>类型：</span>
							<el-radio-group v-model="schoolTypeIs">
								<el-radio class="radio" :label="null" @change="schoolTypeIsChange">不限</el-radio>
								<el-radio class="radio" label="">指定学校类型：</el-radio>
							</el-radio-group>
							<el-checkbox-group v-model="schoolType" :disabled="schoolTypeIs == null"
								@change="schoolTypeChange">
								<el-checkbox v-for="item in schoolTypeData" :label="item.id"
									:key="item.id">{{item.name}}</el-checkbox>
							</el-checkbox-group>
						</div>
					</li>
					<li v-if="insert.range == '1'">
						<div class="div">
							<span>学校：</span>
							<el-radio-group v-model="schoolIs">
								<el-radio class="radio" :label="null" @change="schoolCopy = []">不限</el-radio>
								<el-radio class="radio" label="">指定学校：</el-radio>
							</el-radio-group>
							<el-select ref="schoolSelect" class="select2" multiple collapse-tags clearable
								v-model="schoolCopy" placeholder="请选择要投放的学校(可多选)" :disabled="schoolIs == null">
								<el-option style="display: none;" v-for="(item,index) in schoolAllData" :key="index"
									:value="item.ID" :label="item.SchoolName"></el-option>
								<el-option class="seleceOption" value="">
									<div @click.stop="noOk">
										<p class="optionTitle">
											<span v-for="item in serach" :key="item"
												@click="search(item)">{{item}}</span>
										</p>
										<div class="optionContent">
											<el-checkbox-group class="checkBox" v-model="schoolCopy"
												@change="handleCheckedCitiesChange">
												<el-checkbox v-for="(item,index) in schoolData" :key="index"
													:label="item.ID"
													style="width: 150px;color: #606266;">{{item.SchoolName}}
												</el-checkbox>
											</el-checkbox-group>
										</div>
										<p class="optionFooter">
											<el-checkbox :indeterminate="isIndeterminate" v-model="schoolCheckAll"
												@change="handleCheckAllChange">全部选中</el-checkbox>
											<el-button size="mini" type="primary"
												@click="$refs.schoolSelect.blur()">选择完成</el-button>
										</p>
									</div>
								</el-option>
							</el-select>
							<el-popover v-if="schoolCopy.length > 1" placement="right" trigger="hover"
								style="cursor: pointer;width: 20px;margin-left: 10px;">
								<el-table :data="schoolShow()" max-height="500">
									<el-table-column property="val" label="选择的学校" align="center"
										width="200"></el-table-column>
								</el-table>
								<i slot="reference" class="el-icon-warning-outline"></i>
							</el-popover>
						</div>
					</li>
					<li v-if="insert.range == '2'">
						<div class="div">
							<span>年级：</span>
							<el-radio-group v-model="classTypeIs">
								<el-radio class="radio" :label="null" @change="classTypeCopy = []">不限</el-radio>
								<el-radio class="radio" label="">指定年级：</el-radio>
							</el-radio-group>
							<el-select ref="classSelect" class="select2" multiple collapse-tags clearable
								v-model="classTypeCopy" placeholder="请选择要投放的年级(可多选)" :disabled="classTypeIs == null"
								@visible-change="tabClick">
								<el-option-group style="display: none;" v-for="(item,index) in classData" :key="index"
									:label="item.label">
									<el-option v-for="(option,index) in item.options" :key="index" :label="option.level"
										:value="option.id">
									</el-option>
								</el-option-group>
								<el-option class="seleceOption2" value="">
									<div @click.stop="noOk">
										<el-tabs class="optionTitle" v-model="className" @tab-click="tabClick">
											<el-tab-pane class="tabPane" v-for="(item,index) in classData" :key="index"
												:label="item.label" :name="index.toString()">
												<el-checkbox-group class="checkBox" v-model="classTypeCopy"
													@change="tabClick">
													<el-checkbox
														v-if="fiveAndSix && option.id != '9' || !fiveAndSix && option.id != '13'"
														v-for="(option,index) in item.options" :key="index"
														:label="option.id" style="width: 150px;">
														{{option.level}}
													</el-checkbox>
												</el-checkbox-group>
											</el-tab-pane>
										</el-tabs>
										<p class="optionFooter">
											<el-checkbox :indeterminate="isIndeterminate2" v-model="classCheckAll"
												@change="handleCheckAllChange2">全部选中</el-checkbox>
											<el-button size="mini" type="primary"
												@click="$refs.classSelect.blur()">选择完成</el-button>
										</p>
										<div class="fiveAndSix">
											五四制：<el-switch v-model="fiveAndSix" @change="switchClick"></el-switch>
										</div>
									</div>
								</el-option>
							</el-select>
						</div>
					</li>
					<li v-if="insert.range == '2'">
						<div class="div">
							<span>性别：</span>
							<el-radio-group v-model="insert.gender">
								<el-radio class="radio" label="0">不限</el-radio>
								<el-radio class="radio" label="1">男</el-radio>
								<el-radio class="radio" label="2">女</el-radio>
							</el-radio-group>
						</div>
					</li>
					<li>
						<div class="div">
							<span>出价：</span>
							<el-input class="input" placeholder="请输入价格" v-model="insert.singlePrice">
								<template slot="append">元</template>
							</el-input>
							<span class="span" style="margin-top: 14px;">出价不能低于平台的资源底价，不能高于计划预算的30%</span>
						</div>
					</li>
					<li>
						<div class="div">
							<span>模式：</span>
							<el-radio-group v-model="insert.advType">
								<el-radio class="radio" label="2">信息流</el-radio>
								<el-radio class="radio" label="1">首屏广告</el-radio>
								<el-radio class="radio" label="3">通知栏广告</el-radio>
								<!-- <el-radio class="radio" label="4">详情页广告</el-radio> -->
							</el-radio-group>
						</div>
					</li>
					<el-card class="card">
						<div class="cardDiv">
							<img class="img"
								src="https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxOTA1ODQ0NS4wOTk0Ljg2Nzk4" alt=""
								width="50px" height="100px">
							<span class="span">资源底价</span>
							<el-input class="input" size="mini" :disabled="true" v-model="countMoney"
								style="color: red"></el-input>
							<span class="spanEnd" style="margin-right: 30px;">元</span>
						</div>
					</el-card>
					<li>
						<el-button class="last" size="mini" type="primary" @click="last()">上一步</el-button>
						<el-button class="next" size="mini" type="primary" @click="next()">下一步</el-button>
					</li>
				</ul>
			</div>
		</div>
		<!-- 广告创建页面设置投放范围结束 -->
		<!-- 广告创建页面编辑投放内容开始 -->
		<div class="stepThree" v-if="active==2">
			<div class="content">
				<ul>
					<li v-if="insert.advType == '3'">
						<div class="div">
							<span>推送标题：<el-select v-model="insert.propagandaTitle">
									<el-option v-for="item in certificationOptions" :value="item.push_title"
										:key="item.id">{{item.push_title}}</el-option>
								</el-select>
								<el-button @click="getCertifications"><i
										class="el-icon-refresh"></i></el-button><el-button type="primary"
									@click="certificationAdmin">资质管理</el-button>
							</span>
						</div>
					</li>
					<li v-if="insert.advType == '3'">
						<div class="div">
							<span>推送类型：推送类广告</span>
						</div>
					</li>
					<li v-if="insert.advType != '3'">
						<div class="div">
							<span>推广标题：</span>
							<el-input class="input" size="medium" v-model="insert.propagandaTitle"
								placeholder="当前最多可输入6个汉字" maxlength="6">
							</el-input>
						</div>
					</li>

					<li v-if="insert.advType == '3'">
						<div class="div">
							<span>推送内容&nbsp;&nbsp;&nbsp;&nbsp;：</span>
							<el-input type="textarea" class="input" size="medium" v-model="insert.pushContent"
								placeholder="当前最多可输入30个汉字" maxlength="30">
							</el-input>
						</div>
					</li>
					<li v-if="insert.advType == '2'">
						<div class="div">
							<span>推送类型：</span>
							<el-radio-group v-model="insert.exhibitionType">
								<el-radio class="radio" label="1" @change="imgList = [],imgData = []">单图创意</el-radio>
								<!-- <el-radio class="radio" label="2">大图创意</el-radio> -->
								<el-radio class="radio" label="3" @change="imgList = [],imgData = []">多图创意</el-radio>
							</el-radio-group>
						</div>
					</li>
					<li v-if="insert.advType == '1'">
						<div class="div">
							<span>推送类型：首屏广告</span>
						</div>
					</li>
					<li v-if="insert.advType == '4'">
						<div class="div">
							<span>推送类型：详情页广告</span>
						</div>
					</li>
					<li v-if="insert.advType == '3'">
						<div class="div">
							<div>推送缩略图：</div>
							<el-upload ref="upload" :action="url" list-type="picture-card"
								:limit="insert.exhibitionType == '3' ? 3 : 1" :on-progress="uploadingPush"
								:file-list="imgListPush" accept="image/jpg,image/png,image/jpeg"
								:on-remove="removeImgPush" :headers="{'token':token}" :on-success="fileSuccessPush"
								:on-error="imgError" :on-exceed="limitAlert" style="height: 60px;">
								<i slot="default" class="el-icon-plus"></i>
							</el-upload>
							<div style="font-size:12px;text-align:left;line-height:auto;color:#666;"></div>
						</div>
					</li>
					<li style="font-size:12px;text-align:left;line-height:auto;color:#666;"
						v-if="insert.advType == '3'">
						注：部分机型无法显示，图片大小：200×200 支持jpg、jpeg、png、gif 静图50K以内，动图300K以内</li>
					<li>
						<div class="div">
							<span>{{insert.advType==3 ? '详情页展示图' : '展示图片'}}：</span>
							<el-upload ref="upload" :action="url" list-type="picture-card"
								:limit="insert.exhibitionType == '3' ? 3 : 1" :on-progress="uploading"
								:file-list="imgList" accept="image/jpg,image/png,image/jpeg" :on-remove="removeImg"
								:headers="{'token':token}" :on-success="fileSuccess" :on-error="imgError"
								:on-exceed="limitAlert" style="height: 60px;">
								<i slot="default" class="el-icon-plus"></i>
							</el-upload>
						</div>
					</li>

					<li style="font-size:12px;text-align:left;line-height:auto;color:#666;"
						v-if="insert.advType == '3'">
						图片大小：800×200 支持jpg、jpeg、png、gif 静图50K以内，动图300K以内</li>
					<li>
					<li>
						<div class="div">
							<span style="width:150px;text-align:left;">推广内容：</span>
							<div>
							<!-- 	<el-upload class="upload-demo" :action="url" :file-list="fileList"
									:headers="{'token':token}" :on-success="imgSuccess">
									<el-link type="primary">插入图片</el-link>
								</el-upload> -->
								<editor class="editor" ref="editor" v-model="text" @change="advDetailChange" :disabled="!insert.excel"></editor>
							</div>
						</div>
					</li>
					<li style="display: flex;justify-content: flex-end;height: 2vh;">
						<el-checkbox checked v-model="insert.excel">开启用户反馈表格</el-checkbox>
						<el-popover placement="top" trigger="hover" style="z-index: 9;margin-left: 2px;">
							<img src="https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxNzc4MDI5NC4yMTM4LjU1MTIx"
								alt="提示图片">
							<i slot="reference" class="el-icon-warning-outline"></i>
						</el-popover>
					</li>
					<li class="color" style="display:flex;align-items: center;padding-top:20px;">
						按钮颜色：<el-radio-group v-model="insert.color" style="display:flex;align-items: center;">
							<el-radio style="margin-right:10px;" label="black"></el-radio>
							<div style="margin-right:10px;background:black;width:20px;height:20px;"></div>
							<el-radio style="margin-right:10px;" label="red"></el-radio>
							<div style="margin-right:10px;background:red;width:20px;height:20px;"></div>
							<el-radio style="margin-right:10px;" label="yellow"></el-radio>
							<div style="margin-right:10px;background:yellow;width:20px;height:20px;"></div>
							<el-radio style="margin-right:10px;" label="blue"></el-radio>
							<div style="margin-right:10px;background:blue;width:20px;height:20px;"></div>
							<el-radio style="margin-right:10px;" label="green"></el-radio>
							<div style="margin-right:10px;background:green;width:20px;height:20px;"></div>
							<el-radio style="margin-right:10px;" label="mediumvioletred"></el-radio>
							<div style="margin-right:10px;background:mediumvioletred;width:20px;height:20px;"></div>
							<el-radio style="margin-right:10px;" label="orange"></el-radio>
							<div style="margin-right:10px;background:orange;width:20px;height:20px;"></div>
							<el-radio style="margin-right:10px;" label="custom"></el-radio>
							<div style="font-size:14px;">自定义：#</div><input v-model="insert.customColor"
								style="width:70px;border:0;height:30px;border-bottom:1px solid #ccc;font-size:14px;" />
						</el-radio-group>
					</li>
					<li>
						<el-button class="last" size="mini" type="primary" @click="last()">上一步</el-button>
						<el-button class="next" size="mini" type="primary" @click="next()">下一步</el-button>
					</li>
				</ul>
			</div>
			<div class="content-right">
				<div style="margin-bottom: 30px;text-align:left;">
					<img src="@/assets/iphone.png" style="position:absolute;z-index:9;width:100%;"></img>
					<div class="iphone-screen">
						<div v-show="advertising == true">
							<!-- 首屏广告 -->
							<div v-if="insert.advType == '1'" style="width: 100%;height: 100%;">
								<img :src="iphoneImg" alt="" width="259px" height="510px" />
								<img class="logo"
									src="https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxNzc4MDI5NC4yMTM4LjU1MTIx">
							</div>
							<!-- 单图创意 -->
							<div v-if="insert.advType == '2' && insert.exhibitionType == '1'">
								<div style="position:absolute;width:259px;height:20px;background:white;margin-top:130px;margin-left:15px;">
									{{insert.propagandaTitle || '推广标题'}}
								</div>
								<img :src="iphoneImg2" alt="" width="259px" height="510px" />
								<img class="oneImg" :src="iphoneImg22">
							</div>
							<!-- 多图创意 -->
							<div v-if="insert.advType == '2' && insert.exhibitionType == '3'">
								<img :src="iphoneImg3" alt="" width="259px" height="510px" />
								<img class="threeImg1" :src="iphoneImg31">
								<img class="threeImg2" :src="iphoneImg32">
								<img class="threeImg3" :src="iphoneImg33">
							</div>
							
							<div v-if="insert.advType == 3">
								<div style="transform:scale(0.8);position:absolute;font-size:9px;margin-top:175px;text-align:left;padding:0 30px 0 15px;">{{insert.propagandaTitle}}提醒您：东南门入校摄像头拍到您的孩子小明 </div>
								<div style="word-break:break-all;-webnkit-box-orient:vertical;-webkit-line-clamp:2;height:28px;overflow:hidden;text-overflow:ellipsis;width:100%;transform:scale(0.8);position:absolute;font-size:9px;margin-top:197px;text-align:left;padding:0 0 0 15px;">
								<img :src="iphoneImgPush" style="width:30px;height:30px;float:right;" />
								{{insert.pushContent}}</div>
								<img src="@/assets/iphone-notice.png" style="width:100%;" />
							</div>
						</div>
						<div v-html="insert.advDetail"
							style="width: 259px;height: 510px;text-align: left;overflow: auto;font-size: 14px;"></div>
					</div>

				</div>
				<div style="margin-bottom: 10px;text-align:left;" v-if="insert.excel">
					<img src="@/assets/iphone.png" style="position:absolute;z-index:9;width:100%;"></img>
					<div class="iphone-screen">
						<div style="text-align:center;position:absolute;margin-top:42px;width:100%;z-index: 1;">{{insert.propagandaTitle}}</div>
						<img src="@/assets/iphone-top.png" style="width:100%;height:70px;position:absolute;"/>
						<div style="position:absolute;width:100%;margin-top:70px;padding:0 13px;overflow:auto;height:390px;z-index:99;">
							<div  v-html="insert.advDetail" ></div>
						</div>
						<div style="background:white;width:100%;text-align:center;padding:10px 0;margin-top:460px;">
							<div :style="{background:insert.color == 'custom' ? '#'+insert.customColor : insert.color}" style="display:inline-block;color:white;font-weight:bold;padding:5px 20px;font-size:14px;border-radius: 30px;">立即参与</div>
						</div>
					</div>
				
				</div>
<!-- 				<div class="link">
					<el-link type="primary" :underline="false" @click="advertising = true,content = false">广告位</el-link>
					<el-divider direction="vertical"></el-divider>
					<el-link type="primary" :underline="false" @click="advertising = false,content = true">内容</el-link>
				</div> -->
			</div>
			<!-- <div class="content-right">
				<div style="margin-bottom: 30px;text-align:left;">
					<img src="@/assets/iphone.png" style="position:absolute;z-index:9;width:100%;"></img>
					<div class="iphone-screen">
						<div v-show="advertising == true">
							<div v-if="insert.advType == '1'" style="width: 100%;height: 100%;">
								<img :src="iphoneImg" alt="" width="259px" height="510px" />
								<img class="logo"
									src="https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxNzc4MDI5NC4yMTM4LjU1MTIx">
							</div>
							<div v-if="insert.advType == '2' && insert.exhibitionType == '1'">
								<img :src="iphoneImg2" alt="" width="259px" height="510px" />
								<img class="oneImg" :src="iphoneImg22">
							</div>
							<div v-if="insert.advType == '2' && insert.exhibitionType == '3'">
								<img :src="iphoneImg3" alt="" width="259px" height="510px" />
								<img class="threeImg1" :src="iphoneImg31">
								<img class="threeImg2" :src="iphoneImg32">
								<img class="threeImg3" :src="iphoneImg33">
							</div>
							
							<div v-if="insert.advType == 3">
								<img :src="iphoneImgPush1" style="position: absolute;width:100%;margin-top:430px;height:80px;" />
								<img src="@/assets/iphone-detail.png"  style="width:100%;margin-top:12px;" />
							</div>
						</div>
						<div v-show="content == true" v-html="insert.advDetail"
							style="width: 259px;height: 510px;text-align: left;overflow: auto;font-size: 14px;"></div>
					</div>

				</div>
				<div style="margin-bottom: 10px;text-align:left;" v-if="insert.advType==3 && insert.excel">
					<img src="@/assets/iphone.png" style="position:absolute;z-index:9;width:100%;"></img>
					<div class="iphone-screen">
						<div style="text-align:center;position:absolute;margin-top:42px;width:100%;z-index: 1;">{{insert.propagandaTitle}}</div>
						<img src="@/assets/iphone-top.png" style="width:100%;height:70px;position:absolute;"/>
						<div style="position:absolute;width:100%;margin-top:70px;padding:0 13px;overflow:auto;height:390px;">
							<div  v-html="insert.advDetail" ></div>
						</div>
						
						<div style="position:absolute;width:100%;text-align:center;padding:10px 0;margin-top:450px;z-index:9;">
							<div :style="{background:insert.color == 'custom' ? '#'+insert.customColor : insert.color}" style="display:inline-block;color:white;font-weight:bold;padding:5px 20px;font-size:14px;border-radius: 30px;">立即提交</div>
						</div>
						<img src="@/assets/iphone-form.png" style="width:92%;margin-left:10px;position:absolute;margin-top:300px;" />
					</div>
				
				</div>
			</div> -->
		</div>
		<!-- 广告创建页面编辑投放内容结束 -->
		<!-- 广告创建页面完成步骤开始 -->
		<div class="stepFour" v-if="active==3">
			<div class="content">
				<ul>
					<li>
						<i class="el-icon-check icon"></i>
					</li>
					<li>
						<h2>投放成功</h2>
						<p>请等待审核完成</p>
					</li>
					<li>
						<el-button type="primary" @click="goCreate()">继续创建</el-button>
						<el-button @click="look()">查看投放</el-button>
					</li>
					<li class="li">
						<p>
							投放期限：{{this.insert.launchStartDay + ' - ' + (!!this.insert.launchEndDay ? this.insert.launchEndDay : '不限')}}
						</p>
						<p>
							投放时段：{{this.launchStartHourIs == null ? '全部时段' : this.insert.launchStartHour + ' - ' + this.insert.launchEndHour}}
						</p>
						<p>
							投放范围：{{this.insert.range == '1' ? '精准投放' : '范围投放'}}
						</p>
						<p>
							投放类型：{{insert.advType == '1' ? '首屏广告' : '信息流'}}
						</p>
						<p>
							每日预算：{{this.everydayBudgetIs == null ? '不限' : $util.setMoney(this.insert.everydayBudget)}}
						</p>
					</li>
				</ul>
			</div>
		</div>
		<!-- 广告创建页面完成步骤结束 -->
	</div>
</template>

<script>
	import {
		qualificationsList,
		provinceCityArea,
		getSchool,
		getClassType,
		advAdd,
		advUserInfo
	} from '@/api/agentApi.js'
	import editor from '@/views/utils/editor'
	import Pinyin from '@/views/utils/ChinesePY.js'
	export default {
		components: {
			editor
		},
		data() {
			return {
				active: 0, //创建广告开始步骤点
				url: this.$url + '/upload/img', //上传图片请求地址 线上
				// url: this.$url + 'upload/img', //上传图片请求地址 本地
				token: localStorage.getItem('token'), //token
				fileList: [], //展示图片集合
				imgList: [], //展示图片集合
				imgListPush: [], //展示图片集合
				imgData: [], //展示图片缓存
				imgDataPush: [], //展示图片缓存
				provinceData: [], //省集合
				cityData: [], //市集合
				areaData: [], //区集合
				schoolData: [], //学校集合
				schoolCopy: [], //选择的学校
				classData: [], //年级集合
				classTypeCopy: [], //选择的年级
				className: '0', //年级的tabs标签默认选项
				proviceCitySchoolData: [], //省市区学校集合
				schoolType: [], //学校类型
				schoolAllData: [], //所有学校
				advertising: true, //默认开启广告
				content: false, //广告内容
				fiveAndSix: false, //五四制和六三制
				isIndeterminate: true, //学校全部选中状态
				isIndeterminate2: true, //年级全部选中状态
				schoolCheckAll: false, //学校全部选中复选框
				classCheckAll: false, //年级全部选中复选框
				schoolTypeCopy: '', //学校类型选择时的搜索条件
				schoolTypeIs: null, //学校类型radio
				classTypeIs: null, //年级选择radio
				everydayBudgetIs: null, //每日预算radio
				launchStartHourIs: null, //投放开始时段radio
				provinceIs: null, //省选择radio
				schoolIs: null, //学校选择radio
				iphoneImg: 'https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxODU2ODU0MC40MTczLjY1MzYx', //首屏广告默认图片
				iphoneImg2: 'https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxOTA1ODUyOC4yNzg3LjQ0NDA5', //信息流单图创意默认图片
				iphoneImg3: 'https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxOTA1ODQ0NS4wOTk0Ljg2Nzk4', //信息流多图创意默认图片
				iphoneImg22: 'https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxOTA2MTg2Ni45MjAxLjg0OTA5',
				iphoneImg31: 'https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxOTA2MTg2Ni45MjAxLjg0OTA5',
				iphoneImg32: 'https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxOTA2MTg2Ni45MjAxLjg0OTA5',
				iphoneImg33: 'https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxOTA2MTg2Ni45MjAxLjg0OTA5',
				iphoneImgPush: 'https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxOTA2MTg2Ni45MjAxLjg0OTA5',
				iphoneImgPush1: 'https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxOTA2MTg2Ni45MjAxLjg0OTA5',
				countMoney: '0.01', //系统价格
				text: '', //文本编辑器绑定值
				insert: { // 创建广告表单默认值
					color: 'black',
					pushContent: '', //推送内容
					advName: '', //广告名称
					everydayBudget: '', //每日预算
					launchStartHour: '', //投放开始时段
					launchEndHour: '', //投放结束时段
					launchStartDay: '', //投放开始日期
					launchEndDay: '', //投放结束日期
					range: '1', //投放范围 ( 1精准投放 2范围投放 )
					province: '', //省
					city: '', //市
					area: '', //区
					school: '', //学校
					classType: '', //年级
					advType: '1', //广告模式(1首屏 2信息流 3通知栏广告 4详情页广告)
					singlePrice: '', //单次展示价格
					propagandaTitle: '', //推广标题
					advDetail: '', //广告详情
					exhibitionType: '1', //推送类型(1单图,2大图,3多图)
					gender: '0', //性别1男2女(0表示不限)
					advPic: [], //展示图片
				},
				schoolTypeData: [{
						id: "1",
						name: '幼儿园'
					},
					{
						id: "2",
						name: '小学'
					},
					{
						id: "3",
						name: '初中'
					},
					{
						id: "4",
						name: '高中'
					},
				],
				serach: [
					"全部学校", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R",
					"S", "T",
					"U", "V", "W", "X", "Y", "Z"
				],
				//日期参数
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 3600 * 24 * 1000;
					},
				},
				advData: {}, //广告主信息
				certificationOptions: []
			}
		},
		created() {
			this.getCertifications()
			this.advUserInfo() //广告主信息
			this.proviceCitySchool(); // 获取省市区学校数据
			this.getSchool(); // 获取省市区学校数据
			this.getClassType(); // 获取省市区年级数据
			getSchool({}).then(res => {
				this.schoolAllData = res.data
			}).catch(err => {
				console.log(err)
			})
		},
		methods: {
			changeColor(e, val) {
				if (e == true) {
					this.color = val
				} else {
					this.color = ''
				}
				console.log(this.color)
			},
			//打开资质管理
			certificationAdmin() {
				window.open('/#/adverPlan/certification')
			},
			// 获取资质列表
			getCertifications() {
				const data = {
					page: 1,
					limit: 9999
				}
				qualificationsList(data).then(res => {
					this.certificationOptions = res.data.list
				})
			},
			advUserInfo() { //获取广告主信息
				advUserInfo({}).then(res => {
					this.advData = res.data //获取广告主信息
					this.advData.balance = this.$util.setMoney(this.advData.balance) //余额
					this.advData.todaySpend = this.$util.setMoney(this.advData.todaySpend)
					this.advData.everydayBudget = this.$util.setMoney(this.advData.everydayBudget) //每日预算
				}).catch(err => console.log(err))
			},
			noOk() { //防止单击事件冒泡
				// console.log("占位使用！")
			},
			search(val) { //学校按照首字母模糊查询
				if (val == '全部学校') {
					this.getSchool() //获取全部学校数据
				} else {
					let data = {
						province: this.insert.province,
						city: this.insert.city,
						area: this.insert.area,
						schoolType: this.schoolTypeCopy
					}
					getSchool(data).then(res => {
						this.schoolData = res.data.filter(item => {
							return Pinyin.Pinyin.GetJP(item.SchoolName).toUpperCase().indexOf(val) != -1
						})
					})
				}
			},
			schoolShow() { //悬浮显示选择的学校
				var data = []
				for (let i = 0; i < this.schoolCopy.length; i++) {
					for (let x = 0; x < this.schoolAllData.length; x++) {
						if (this.schoolCopy[i] == this.schoolAllData[x].ID) {
							data.push({
								val: this.schoolAllData[x].SchoolName
							})
						}
					}
				}
				// var s = data.join(",");
				return data;
			},
			handleCheckAllChange(val) { //学校全部选中复选框事件
				var list = this.schoolData.map(item => {
					return item.ID
				})
				if (!this.schoolCopy) {
					this.schoolCopy = val ? list : [];
				} else {
					list = list.concat(this.schoolCopy)
					list = Array.from(new Set(list))
					this.schoolCopy = val ? list : [];
				}
				this.isIndeterminate = false;
			},
			handleCheckedCitiesChange(value) { //学校选中复选框事件
				let checkedCount = value.length;
				var list = this.schoolData.map(item => {
					return item.ID
				})
				this.isInclude(list, value) ? this.schoolCheckAll = true : this.schoolCheckAll = false;
				this.isIndeterminate = this.schoolCheckAll ? false : true;
			},
			handleCheckAllChange2(val) { //年级全部选中复选框事件
				let list = this.befoGetList();
				if (!this.fiveAndSix && this.className == '2') {
					list.splice(list.indexOf('13'), 1)
				} else if (this.fiveAndSix && this.className == '1') {
					list.splice(list.indexOf('9'), 1)
				}
				if (val) {
					this.classTypeCopy = this.classTypeCopy.concat(list)
				} else {
					this.classTypeCopy = this.classTypeCopy.filter(item => !list.includes(item))
				}
				this.isIndeterminate2 = false;
			},
			// handleCheckedCitiesChange2(value) { //年级选中复选框事件
			//   let checkedCount = value.length;
			//   this.classCheckAll = checkedCount === this.classTypeCopy.length;
			//   this.isIndeterminate2 = checkedCount > 0 && checkedCount < this.classData[parseInt(this.className)].options
			//     .length;
			// },
			isInclude(aa, bb) { //判断数组b中是否全部包含数组a
				return aa.every((item) => {
					return bb.some((sub) => {
						return sub == item
					})
				})
			},
			isInclude2(aa, bb) { //判断数组b中是否包含数组a的元素
				return aa.some((item) => {
					return bb.some((sub) => {
						return sub == item
					})
				})
			},
			switchClick(val) { //切换五四制和六三制
				if (!val && this.classTypeCopy.includes('13')) {
					this.classTypeCopy.splice(this.classTypeCopy.indexOf('13'), 1)
				} else if (val && this.classTypeCopy.includes('9')) {
					this.classTypeCopy.splice(this.classTypeCopy.indexOf('9'), 1)
				}
			},
			tabClick() { //判断全选框显示
				let list = this.befoGetList();
				if (!this.fiveAndSix && this.className == '2') {
					list.splice(list.indexOf('13'), 1)
				} else if (this.fiveAndSix && this.className == '1') {
					list.splice(list.indexOf('9'), 1)
				}
				this.isInclude(list, this.classTypeCopy) ? this.classCheckAll = true : this.classCheckAll = false
				if (this.classCheckAll) {
					this.isIndeterminate2 = false
				} else {
					this.isIndeterminate2 = true
				}
			},
			proviceCitySchool() { // 获取省市区学校数据
				provinceCityArea().then(res => {
					this.proviceCitySchoolData = res.data //保存省市区学校数据
					this.provinceData = res.data.province // 省
				}).catch(err => {
					console.log(err)
				})
			},
			getSchool() { //获取学校数据
				let data = {
					province: this.insert.province,
					city: this.insert.city,
					area: this.insert.area,
					schoolType: this.schoolTypeCopy
				}
				getSchool(data).then(res => {
					this.schoolData = res.data
				}).catch(err => {
					console.log(err)
				})
			},
			getClassType() { //获取年级数据
				// let data = {
				//   province: this.insert.province,
				//   city: this.insert.city,
				//   area: this.insert.area,
				//   schoolType: this.schoolTypeCopy
				// }
				getClassType({}).then(res => {
					var list = new Array(5).fill({}).map(item => {
						return {
							label: '',
							options: []
						}
					})
					list[0].label = '幼儿园阶段'
					list[1].label = '小学阶段'
					list[2].label = '初中阶段'
					list[3].label = '高中阶段'
					list[4].label = '大学阶段'
					for (var i = 0; i < res.data.length; i++) {
						if (res.data[i].schoolTypeId === '1') {
							list[0].label = '幼儿园阶段',
								list[0].options.push(res.data[i])
						} else if (res.data[i].schoolTypeId === '2') {
							list[1].label = '小学阶段',
								list[1].options.push(res.data[i])
						} else if (res.data[i].schoolTypeId === '3') {
							list[2].label = '初中阶段',
								list[2].options.push(res.data[i])
						} else if (res.data[i].schoolTypeId === '4') {
							list[3].label = '高中阶段',
								list[3].options.push(res.data[i])
						} else if (res.data[i].schoolTypeId === '5') {
							list[4].label = '大学阶段',
								list[4].options.push(res.data[i])
						}
					}
					this.classData = list
				}).catch(err => {
					console.log(err)
				})
			},
			provinceChange() { // 地区点击不限
				this.insert.province = '' //清空省
				this.insert.city = '' // 清空城市
				this.insert.area = '' // 清空区域
				if (this.insert.range == '1') {
					this.getSchool() //获取全部学校数据
				}
			},
			province(val) { // 省份选择
				this.insert.city = '' // 省份变动清空城市
				this.insert.area = '' // 省份变动清空区域
				this.cityData = this.proviceCitySchoolData.city.filter(item => item.ProvinceId == val)
				if (this.insert.range == '1') {
					this.getSchool() //获取全部学校数据
				}
			},
			city(val) { // 城市选择
				this.insert.area = '' // 城市变动清空区域
				this.areaData = this.proviceCitySchoolData.area.filter(item => item.CityId == val)
				if (this.insert.range == '1') {
					this.getSchool() //获取全部学校数据
				}
			},
			area(val) { // 区域选择
				if (this.insert.range == '1') {
					this.getSchool() //获取全部学校数据
				}
			},
			schoolTypeChange(val) { //学校类型选择变化时
				let i = val.join('#')
				this.schoolTypeCopy = i
				if (this.insert.range == '1') {
					this.getSchool() //获取全部学校数据
				}
			},
			schoolTypeIsChange() { //点击学校类型不限时调用
				this.schoolType = []
				this.schoolTypeCopy = ''
				if (this.insert.range == '1') {
					this.getSchool() //获取全部学校数据
				}
			},
			advAdd() { //创建广告
				
			
			
				if (!!this.insert.launchStartHour) { //格式化开始投放时段
					this.insert.launchStartHour = this.insert.launchStartHour.charAt(0) == "0" ? this.insert
						.launchStartHour.substr(1, 1) : this.insert.launchStartHour.substr(0, 2)
				}
				if (!!this.insert.launchEndHour) { //格式化结束投放时段
					this.insert.launchEndHour = this.insert.launchEndHour.charAt(0) == "0" ? this.insert.launchEndHour
						.substr(1, 1) : this.insert.launchEndHour.substr(0, 2)
				}
				if (!!this.insert.launchStartDay) { //格式化开始投放期限
					this.insert.launchStartDay = this.insert.launchStartDay.replace(/-/g, "")
				}
				if (!!this.insert.launchEndDay) { //格式化结束投放时段
					this.insert.launchEndDay = this.insert.launchEndDay.replace(/-/g, "")
				}
				if (!!this.insert.everydayBudget) {
					this.insert.everydayBudget = this.$util.getMoney(parseFloat(this.insert.everydayBudget)) +
						'' //将每日预算转换成厘为单位
				}
				this.insert.singlePrice = this.$util.getMoney(parseFloat(this.insert.singlePrice)) //将出价转换成厘为单位
				if (this.schoolCopy.length > 0) {
					this.insert.school = this.schoolCopy.join('#') //拼接选择的学校
				}
				if (this.classTypeCopy.length > 0) {
					this.insert.classType = this.classTypeCopy.join('#') //拼接选择的年级
				}
				const data = []
				for (var i = 0; i < this.imgData.length; i++) { //拼接选择的图片
					data.push(this.imgData[i].url)
				}
				if (this.insert.advType == 3) {
					data.unshift(this.imgDataPush[0].url)
				}
				this.insert.advPic = JSON.stringify(data)
				
				console.log(this.insert)
				
				const formData = {
					...this.insert,
					intro: this.insert.pushContent,
					detail_color: this.insert.color == 'custom' ? '#'+this.insert.customColor : this.insert.color
				}
				
				
				advAdd(formData).then(res => {
					if (res.status.code == 1) {
						this.active++;
					} else {
						this.$message.error(res.status.msg)
					}
				}).catch(err => {
					console.log(err)
				})
			},
			next() { //点击下一步按钮
				if (this.active == 0) {
					if (this.advData.balance == 0) {
						this.$message.error('您账户没有余额 请先联系客服充值 充值后请刷新页面')
					} else if (this.advData.everydayBudget == 0) {
						this.$message.error('请去调整左侧的 每日预算 然后请刷新页面')
					} else if (!this.insert.advName || this.insert.advName.length > 40) {
						this.$message.error('广告计划名称是必填的,并且最多可输入40个汉字！')
					} else if (this.everydayBudgetIs != null && !this.insert.everydayBudget) {
						this.$message.error('每日预算是必填的')
					} else if (this.everydayBudgetIs != null && (!this.isNumber(this.insert.everydayBudget) || this
							.isNumberLength(this.insert.everydayBudget) > 3)) {
						this.$message.error('每日预算必须是数字并且最多精确到厘')
					} else if (!this.insert.launchStartDay) {
						this.$message.error('投放期限是必填的')
					} else if (!this.insert.range) {
						this.$message.error('投放范围是必填的')
					} else if (this.launchStartHourIs != null && (!this.insert.launchStartHour || !this.insert
							.launchEndHour)) {
						this.$message.error('投放时段是必填的')
					} else if (this.launchStartHourIs != null && (parseInt(this.insert.launchStartHour.substr(0, 2)) >=
							parseInt(
								this.insert.launchEndHour.substr(0, 2)))) {
						this.$message.error('投放结束时段要大于投放开始时段')
					} else {
						this.active++;
					}
				} else if (this.active == 1) {
					console.log(this.insert.exhibitionType)
					this.insert.exhibitionType = '1';

					if (this.provinceIs != null && (!this.insert.province || !this.insert.city)) {
						this.$message.error('请选择指定地区')
					} else if (this.schoolIs != null && this.insert.range == 1 && this.schoolCopy.length == 0) {
						this.$message.error('请选择指定学校')
					} else if (this.classTypeIs != null && this.classTypeCopy.length == 0) {
						this.$message.error('请选择指定年级')
					} else if (!this.insert.gender) {
						this.$message.error('性别是必填的')
					} else if (!this.insert.advType) {
						this.$message.error('模式是必填的')
					} else if (!this.insert.singlePrice) {
						this.$message.error('出价是必填的')
					} else if (this.insert.singlePrice < this.countMoney) {
						this.$message.error('出价必须大于系统推荐最低价格')
					} else if (this.insert.singlePrice > this.insert.everydayBudget * 0.3 && this.insert.everydayBudget !=
						"") {
						this.$message.error('单价不能超过预算的30%')
					} else if (!this.isNumber(this.insert.singlePrice) || this.isNumberLength(this.insert.singlePrice) >
						3) {
						this.$message.error('出价价格必须是数字并且最多精确到厘')
					} else {
						this.active++
						this.imgData = []
					}
				} else if (this.active == 2) {
					
					if (this.color == 'custom' && !this.customColor) {
						this.$message.error('请填写自定义颜色')
						return
					}
					if (!this.insert.propagandaTitle || this.insert.propagandaTitle.length > 40) {
						this.$message.error('推广标题是必填的,并且最多可输入40个汉字！')
					} else if (!this.insert.exhibitionType) {
						this.$message.error('推送类型是必选的')
					} else if (this.imgData.length == 0) {
						this.$message.error('展示图片是必须的')
					} else if (this.insert.exhibitionType == '3' && this.imgData.length < 3) {
						this.$message.error('多图创意下展示图片需要3张')
					} else {
						this.advAdd()
					}
				}
			},
			last() { //点击上一步按钮
				this.active--;
			},
			isNumber(val) { //判断字符串是不是数字
				var regPos = /^\d+(\.\d+)?$/; //非负浮点数
				// var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
				if (regPos.test(val)) {
					return true;
				} else {
					return false;
				}
			},
			isNumberLength(val) { //判断小数点后面位数
				if (val.indexOf('.') != -1) {
					let numlength = val.indexOf('.') + 1
					let length = val.substring(numlength).length
					return length
				} else {
					return 0
				}
			},
			startDayChange(val) { //判断开始投放期限是否大于当前日期
				let start = new Date(val).getTime(); //获取开始日期时间戳
				var newDate = new Date().getTime() - 24 * 60 * 60 * 1000; //获取上一天时间戳
				if (newDate > start) {
					this.$message.error('投放开始日期不能小于当前日期');
					this.insert.launchStartDay = '';
				}
				/*
				  判断结束日期是否大于开始日期
				  如果小于开始日期
				  将结束日期清空重填
				*/
				if (!!this.insert.launchEndDay) {
					let end = new Date(this.insert.launchEndDay).getTime(); //获取结束日期时间戳
					if (start > end) {
						this.$message.error('投放结束日期要大于投放开始日期');
						this.insert.launchEndDay = '';
					}
				}
			},
			endDayChange(val) { //判断结束投放期限是否大于投放开始日期
				let end = new Date(val).getTime(); //获取结束日期时间戳
				let start = new Date(this.insert.launchStartDay).getTime(); //获取开始日期时间戳
				if (start > end) {
					this.$message.error('投放结束日期要大于投放开始日期');
					this.insert.launchEndDay = '';
				}
			},
			goCreate() { //点击继续创建
				location.reload();
			},
			look() { //查看投放
				this.$router.push('/advertising/adverList')
			},
			limitAlert() { //展示图片超过张数提醒
				if (this.insert.advType == '2' && this.insert.exhibitionType == '1') {
					this.$message.error('单图创意下只能上传1张图片！')
				} else if (this.insert.advType == '2' && this.insert.exhibitionType == '3') {
					this.$message.error('多图创意下只能上传3张图片！')
				} else {
					this.$message.error('只能上传1张图片！')
				}
			},
			fileSuccessPush(response, file, fileList) {
				let data = {
					imgId: file.uid,
					url: response.data.url
				}
				this.imgDataPush.push(data)
				this.iphoneImgPush = response.data.url
			},

			fileSuccess(response, file, fileList) { //展示图片上传成功
				let data = {
					imgId: file.uid,
					url: response.data.url
				}
				this.imgData.push(data)
				if (this.insert.advType == '1') { //首屏图片更换
					this.iphoneImg = response.data.url
				} else if (this.insert.advType == '2' && this.insert.exhibitionType == '1') { //单图创意图片更换
					this.iphoneImg22 = response.data.url
				} else if (this.insert.advType == '2' && this.insert.exhibitionType == '3') { //多图创意图片更换
					if (this.imgData.length > 0) {
						this.iphoneImg31 = this.imgData[0].url
					}
					if (this.imgData.length > 1) {
						this.iphoneImg32 = this.imgData[1].url
					}
					if (this.imgData.length > 2) {
						this.iphoneImg33 = this.imgData[2].url
					}
				}
				if (this.insert.advType == 3) {
					this.iphoneImgPush1 = response.data.url
				}
			},

			uploadingPush(event, file, fileList) {
				if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png' || file
					.raw.type == 'image/gif') {

					// 推送素材  动态图图片大小不能超过300K
					if (this.insert.advType == 3 && file.raw.type == 'image/gif' && file.raw.size > 300000) {
						this.$message.error('图片大小不能超过300K！')
						this.$refs.upload.abort() //取消上传
						this.imgListPush = fileList.filter(item => item.uid != file.uid)
						return
					}

					// 推送素材  静态图图片大小不能超过50K
					if (this.insert.advType == 3 && file.raw.type != 'image/gif' && file.raw.size > 50000) {
						this.$message.error('图片大小不能超过50K！')
						this.$refs.upload.abort() //取消上传
						this.imgListPush = fileList.filter(item => item.uid != file.uid)
						return
					}
				}
			},

			uploading(event, file, fileList) {
				//判断用户上传文件类型和大小
				if (file.raw.type == 'image/jpeg' || file.raw.type == 'image/jpg' || file.raw.type == 'image/png' || file
					.raw.type == 'image/gif') {
					const size = file.size / 1024


					// 推送素材  动态图图片大小不能超过300K
					if (this.insert.advType == 3 && file.raw.type == 'image/gif' && size > 300) {
						this.$message.error('图片大小不能超过300K！')
						this.$refs.upload.abort() //取消上传
						this.imgList = fileList.filter(item => item.uid != file.uid)
						return
					}

					// 推送素材  静态图图片大小不能超过50K
					if (this.insert.advType == 3 && file.raw.type != 'image/gif' && size > 50) {
						this.$message.error('图片大小不能超过50K！')
						this.$refs.upload.abort() //取消上传
						this.imgList = fileList.filter(item => item.uid != file.uid)
						return
					}

					if (size > 1024) {
						this.$message.error('图片大小不能超过1M！')
						this.$refs.upload.abort() //取消上传
						this.imgList = fileList.filter(item => item.uid != file.uid)
					} else {
						this.$message.success("图片上传成功！")
					}
				} else {
					this.$message.error("只可以上传jpeg、jpg和png格式图片，请重新上传！")
					this.imgList = fileList.filter(item => item.uid != file.uid)
					this.$refs.upload.abort() //取消上传
				}
			},
			removeImgPush(file, fileList) { //展示图片删除提醒
				this.$message.success('图片删除成功！')
				this.imgDataPush = this.imgDataPush.filter(item => item.imgId != file.uid)
			},
			removeImg(file, fileList) { //展示图片删除提醒
				this.$message.success('图片删除成功！')
				this.imgData = this.imgData.filter(item => item.imgId != file.uid)
			},
			imgError(err, file, fileList) { //展示图片上传失败提醒
				this.$message.error('图片上传失败！')
			},
			advDetailChange(data) { //富文本回调函数，修改后的文章内容
				this.insert.advDetail = data
			},
			imgSuccess(response) { //将上传的图片传到富文本中
				this.$refs.editor.insertImg(response.data.url)
			},
			befoGetList() { //监听指定年级标签切换数据集合
				let list = []
				for (let x = 0; x < this.classData[parseInt(this.className)].options.length; x++) {
					list.push(this.classData[parseInt(this.className)].options[x].id)
				}
				return list
			}
		},
		watch: {

		}
	}
</script>
<style lang="scss" scoped>
	#body {
		background-color: #F0F2F5;
	}

	.seleceOption {
		width: 600px;
		height: 212px;
		margin: 0;
		padding: 0;

		.optionTitle {
			margin: 0;
			padding: 0;
			height: 40px;
			line-height: 40px;
			padding-left: 20px;
			color: #1890FF;
			cursor: default;

			span {
				font-size: 18px;
				margin-right: 5px;
				cursor: pointer;
			}
		}

		.optionContent {
			cursor: default;
			height: 130px;
			width: 96%;
			margin: 0 auto;
			overflow: auto;
			border-top: 1px solid gray;

			.checkBox {
				display: flex;
				flex-wrap: wrap;
				padding-left: 10px;
			}
		}

		.optionFooter {
			width: 96%;
			margin: 0 auto;
			height: 40px;
			line-height: 40px;
			padding: 0 10px;
			cursor: default;
			display: flex;
			justify-content: space-between;
		}
	}

	.seleceOption2 {
		width: 620px;
		height: 212px;
		margin: 0;
		padding: 0;
		position: relative;

		.fiveAndSix {
			position: absolute;
			top: 3px;
			right: 2px;
		}

		.optionTitle {
			// margin: 0;
			// padding: 0;
			width: 96%;
			margin: 0 auto;
			height: 170px;
			cursor: default;

			.tabPane {
				.checkBox {
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;
				}
			}
		}

		.optionFooter {
			width: 96%;
			margin: 0 auto;
			height: 40px;
			line-height: 40px;
			padding: 0 10px;
			cursor: default;
			display: flex;
			justify-content: space-between;
		}
	}

	// /deep/.el-select-group__title {
	//   font-size: 18px;
	//   color: red;
	// }

	.header {
		width: 100%;
		height: 10vh;
		background: white;
		text-align: left;
		padding-top: 10px;
		padding-left: 1.3vw;

		.headerTitle {
			font-size: 14px;
			color: #909399;
			border-bottom: 1px solid #E8E8E8;

			.title {
				display: inline-block;
				font-size: 20px;
				color: #1890FF;
				height: 32px;
				font-weight: bold;
				margin-left: 10px;
				margin-right: 5px;
				border-bottom: 2px solid #1890FF;
			}
		}
	}

	.top {
		width: 100%;
		text-align: left;
		margin: 0 auto;
		background: white;

		.topStep {
			width: 70%;
			margin: 0 auto;
			padding-top: 10px;
		}
	}

	.stepOne {
		.content {
			display: flex;
			justify-content: center;
			width: 100%;
			margin: 0 auto;
			background: white;

			ul {
				display: flex;
				flex-direction: column;
				list-style-type: none;
				margin: 0;
				padding: 0;
				margin-top: 5vh;

				li {
					display: flex;
					justify-content: flex-start;
					line-height: 6vh;

					.div {
						width: 100%;
						display: flex;
						flex-direction: row;
						align-items: center;

						span {
							width: 10vw;
							text-align: right;
						}

						.input2 {
							width: 15vw;
							// margin-top: 1vh;
						}

						.input1 {
							width: 24vw;
						}

						.date {
							width: 15.6vw;
							margin-top: 1vh;
						}

						.radio {
							line-height: 6vh;
						}
					}

					.next {
						width: 70px;
						height: 40px;
						margin-left: 10vw;
						margin-top: 30px;
					}

					.hint {
						font-size: 15px;
						color: #909399;
						margin-left: 30px;
						margin-top: 20px;
					}
				}
			}
		}

		.footer {
			width: 100%;
			background: white;
			text-align: left;
			padding-top: 5px;
			padding-left: 5vw;
			color: #909399;

			.footerBox {
				display: flex;
				justify-content: flex-start;
					font-size: 14px;
					.title {
						font-size: 16px;
						font-weight: bold;
					}

				.footerLeft {
					margin-right: 10vw;
				}
			}
		}
	}

	.stepTwo {
		.content {
			display: flex;
			justify-content: center;
			width: 100%;
			margin: 0 auto;
			background: white;

			ul {
				display: flex;
				flex-direction: column;
				list-style-type: none;
				margin-top: 5vh;

				li {
					display: flex;
					justify-content: flex-start;
					line-height: 6vh;

					.div {
						width: 100%;
						display: flex;
						flex-direction: row;
						align-items: center;
						

						span {
							width: 5vw;
							text-align: right;
						}

						.input {
							width: 10vw;
							// margin-top: 1vh;
						}

						.select {
							width: 8vw;
							margin-right: 10px;
						}

						.select2 {
							width: 16.6vw;
						}

						.radio {
							line-height: 6vh;
						}

						.span {
							width: 25vw;
							font-size: 15px;
							color: #909399;
							text-align: center;
						}
					}

					.next {
						width: 70px;
						height: 40px;
						margin-left: 20px;
						margin-top: 30px;
					}

					.last {
						width: 70px;
						height: 40px;
						margin-left: 15vw;
						margin-top: 30px;
					}
				}

				.card {
					width: 60%;
					background: #F0F2F5;
					background: white;
					margin-left: 50px;


					.cardDiv {
						width: 100%;
						height: 100px;
						background: white;
						text-align: left;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						position: relative;

						.img {
							position: absolute;
							left: 2%;
						}

						.span {
							position: absolute;
							left: 20%;
							font-size: 14px;
							color: #909399;
						}

						.input {
							width: 60px;
							position: absolute;
							right: 15%;
							bottom: 1px;
						}

						.spanEnd {
							font-size: 14px;
							color: #909399;
							position: absolute;
							right: 3%;
							bottom: 5px;
						}

						/deep/ .el-input__inner {
							color: red;
							font-size: 14px;
							font-weight: 550;
						}
					}
				}
			}
		}
	}

	.stepThree {
		display: flex;
		width: 100%;
		justify-content: center;
		background: white;
		margin: 0 auto;

		.content-right {
			display: flex;
			flex-direction: column;
			// background-color: red;
			position: relative;

			// .img {
			//   margin: auto;
			//   width: 100%;
			//   height: 100%;
			//   border-radius: 15%;
			//   background-color: red;
			// }
			.iphone {
				position: absolute;
				top: -30px;
				box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px #999, 0 0 30px 0px rgba(0, 0, 0, 0.7);
				border: 5px solid #d9dbdc;
				background: #f8f8f8;
				// padding: 15px;
				border-radius: 50px;
				height: 590px;
				width: 300px;
				position: relative;
				-webkit-transform: scale(0.8);
				transform: scale(0.8);
			}

			.iphone-top {
				padding: 10px 70px 20px;
				position: relative;
			}

			.iphone-top .speaker {
				display: block;
				width: 70px;
				height: 6px;
				margin: 0 auto;
				border-radius: 6px;
				background: #292728;
			}

			.iphone-top .camera {
				display: block;
				margin: 0 auto;
				height: 6px;
				width: 6px;
				border-radius: 50%;
				margin-bottom: 13px;
				background: #333;
			}

			.iphone-top .sensor {
				display: block;
				width: 10px;
				height: 10px;
				float: left;
				background: #333;
				margin-top: -2px;
				border-radius: 50%;
			}

			.iphone .top-bar,
			.iphone .bottom-bar {
				display: block;
				width: 300px;
				height: 10px;
				border-left: 5px solid #BBB;
				border-right: 5px solid #BBB;
				position: absolute;
				left: -5px;
			}

			.iphone .top-bar {
				top: 40px;
			}

			.iphone .bottom-bar {
				bottom: 40px;
			}

			.iphone-screen {
				background: #eee;
				// border: 1px solid #fff;
				height: 508px;
				width: 259px;
				margin: 0 auto;
				// border: 2px solid rgba(0, 0, 0, 0.9);
				// border-radius: 3px;
				overflow: hidden;
				position: relative;

				.logo {
					position: absolute;
					width: 40%;
					bottom: 2%;
					left: 30%;
					z-index: 9999;
				}

				.oneImg {
					position: absolute;
					width: 96%;
					height: 30%;
					bottom: 40%;
					left: 2%;
					z-index: 1;
				}

				.threeImg1 {
					position: absolute;
					width: 28.5%;
					height: 14%;
					bottom: 46%;
					left: 5%;
					z-index: 9999;
				}

				.threeImg2 {
					position: absolute;
					width: 28.5%;
					height: 14%;
					bottom: 46%;
					left: 35%;
					z-index: 9999;
				}

				.threeImg3 {
					position: absolute;
					width: 28.5%;
					height: 14%;
					bottom: 46%;
					left: 65.5%;
					z-index: 9999;
				}
			}

			.iphone .buttons .on-off,
			.iphone .buttons .up,
			.iphone .buttons .down,
			.iphone .buttons .sleep {
				display: block;
				background: #CCC;
				position: absolute;
				border-radius: 2px 0px 0px 2px;
			}

			.iphone .buttons .on-off {
				height: 30px;
				width: 3px;
				top: 70px;
				left: -8px;
			}

			.iphone .buttons .up,
			.iphone .buttons .down,
			.iphone .buttons .sleep {
				height: 40px;
				width: 5px;
				left: -8px;
			}

			.iphone .buttons .up {
				top: 120px;
			}

			.iphone .buttons .down {
				top: 170px;
			}

			.iphone .buttons .sleep {
				left: auto;
				right: -10px;
				top: 120px;
				border-radius: 0px 2px 2px 0px;
			}

			.iphone-bottom {
				padding: 7px 0 0;
			}

			.iphone-bottom span {
				display: block;
				margin: 0 auto;
				width: 50px;
				height: 50px;
				background: #ccc;
				border-radius: 50%;
				background: -webkit-linear-gradient(315deg, #303233 0%, #b5b7b9 50%, #f0f2f2 69%, #303233 100%);
				background: linear-gradient(135deg, #303233 0%, #b5b7b9 50%, #f0f2f2 69%, #303233 100%);
				position: relative;
			}

			.iphone-bottom span:after {
				content: "";
				display: block;
				margin: 0 auto;
				width: 42px;
				height: 42px;
				background: #fff;
				border-radius: 50%;
				position: absolute;
				left: 4px;
				top: 4px;
			}

			.link {
				position: absolute;
				bottom: 75px;
				left: 100px;
			}
		}

		.content {
			display: flex;
			justify-content: center;
			width: 40%;
			margin-top: 5vh;
			margin-right: 10px;

			ul {
				display: flex;
				flex-direction: column;
				list-style-type: none;
				margin: 0;
				padding: 0;

				li {
					display: flex;
					justify-content: flex-start;
					line-height: 6vh;

					.div {
						display: flex;
						flex-direction: row;

						.input {
							width: 20vw;
						}

						.radio {
							line-height: 6vh;
						}

						.box-card {
							width: 25vw;
							height: 30vh;
							border: 1px solid #DCDFE6;
							margin-top: 15px;
							z-index: 1;

							.upload-demo {
								width: 100%;
								height: 40px;
								line-height: 40px;
								text-align: right;
								padding-right: 10px;
								border-bottom: 1px solid #DCDFE6;

								/deep/.el-upload {
									height: 40px;
									line-height: 40px;
									text-align: right;
								}

								/deep/.el-upload-list {
									display: none;
								}
							}

							/deep/.w-e-text-container {
								height: 20vh !important;
								border: none !important;
							}
						}

						/deep/ .el-upload {
							width: 60px;
							height: 60px;
							line-height: 65px;
						}

						/deep/ .el-upload-list--picture-card .el-upload-list__item {
							width: 60px;
							height: 60px;
							line-height: 60px;
						}

						/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
							width: 60px;
							height: 60px;
							line-height: 60px;
						}
					}

					.next {
						width: 70px;
						height: 40px;
						margin-left: 20px;
						margin-top: 30px;
					}

					.last {
						width: 70px;
						height: 40px;
						margin-left: 15vw;
						margin-top: 30px;
					}
				}
			}
		}
	}

	.stepFour {
		.content {
			display: flex;
			justify-content: center;
			width: 100%;
			margin: 0 auto;
			background: white;

			ul {
				width: 25vw;
				display: flex;
				flex-direction: column;
				list-style-type: none;
				margin: 0;
				padding: 0;
				margin-top: 5vh;

				li {
					margin-bottom: 2vh;

					.icon {
						width: 60px;
						height: 60px;
						background-color: #67C23A;
						color: white;
						border-radius: 50%;
						line-height: 60px;
						font-size: 30px;
						font-weight: bold;
					}

					p {
						font-size: 14px;
						color: #909399;
					}
				}

				.li {
					text-align: left;
				}
			}
		}
	}
	 /deep/ .color .el-radio__label {
		display:none;
	}
</style>