<template>
	<div>
		<div id="editor" style="text-align:left"></div>
	</div>
</template>

<script>
	import WangEditor from "wangeditor"
	export default {
		name: "editor",
		model: {
			prop: "editorContent",
			event: "change"
		},
		props: {
			editorContent: {
				required: true
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				edi: null
			}
		},
		mounted() {
			/*实例化*/
			var editor = new WangEditor("#editor");
			this.edi = editor
			/*设置上传图片*/
			/* editor.config.uploadImgServer = "/upload/image/";
			editor.config.uploadFileName = "fileToUpload";
			editor.config.uploadImgHooks = {
			  customInsert: function(insertImg, result, editor) {
			    var url = result.data;
			    insertImg(url);
			  }
			}; */
			/*绑定回馈事件*/
			editor.config.onchange = html => {
				this.$emit("change", html);
			};
			editor.config.height = 500
			editor.config.menus = ['head',
				'bold',
				'fontSize',
				'fontName',
				'italic',
				'underline',
				'strikeThrough',
				'indent',
				'lineHeight',
				'foreColor',
				'backColor',
				'link',
				'justify',
				'image',
				'video',
				'table',
				'splitLine',
				'undo',
				'redo',
			] //配置菜单栏
			editor.config.showFullScreen = true //是否需要全屏属性
			editor.config.uploadImgServer = this.$url + '/upload/img'
			editor.config.uploadFileName = 'file'
			editor.config.uploadImgHeaders = {
				token: localStorage.getItem('token')
			}

			editor.config.uploadImgHooks = {
				fail: function(xhr, editor, resData) {
					console.log('error', resData)
				},
				customInsert: function(insertImgFn, result) {
					console.log('customInsert', result)
					insertImgFn(result.data.url)
				}
			}
			// editor.config.withCredentials = true
			/*创建编辑器*/
			editor.create();
		},
		methods: {
			setHtml(data) {
				/*初始内容*/
				this.edi.txt.html(data);
			},
			insertImg(path) { //创建广告页面广告详情拼接上传图片使用
				this.edi.txt.append('<img src=' + path + ' width="259px"/>')
			}
		},
		watch: {
			editorContent(newVal, oldVal) {
				this.editorContent = newVal;
			},
			disabled(val) {
				val ? this.edi.disable() : this.edi.enable()
				console.log(val)
			}
		}
	};
</script>